.section__product {
  position: relative;
  color: $dark70;
  padding-bottom: 88px;

  // TEST OVERLAY
  & .modal-overlay-test {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100001;
    opacity: 0;
    visibility: hidden;
    display: grid;
    place-items: center;

    &.isActive {
      opacity: 1;
      visibility: visible;
    }

    & .inner {
      padding: 30px;
      width: calc(100% - 60px);
      height: calc(100% - 60px);
      background-color: $white;
    }
  }

  // PRODUCT WRAPPER
  &--wrapper {
    & .row {
      display: grid;
      grid-gap: 30px;

      @media (min-width: 1540px) {
        grid-template-columns: repeat(2, 1fr);
      }

      & .col {
        & > .wrapper {
          display: grid;
          grid-gap: 30px;
        }
      }
    }
  }

  & .form {
    & .form-group {
      position: relative;
    }
  }

  // PRODUCT CARD
  .product__card {
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    &--info,
    &--gallery,
    &--type,
    &--tags,
    &--upccode {
      & .card-header {
        padding: 20px;
        border-bottom: 1px solid #d9d9d9;

        & h4 {
          margin-bottom: 0;
        }

        &-between {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    &--tags {
      & .tags {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: 1fr 80px;
      }

      & .result {
        min-height: 50px;
        display: grid;
        grid-gap: 10px;
        border-top: 1px solid #d9d9d9;
        padding-top: 20px;

        // TODO: check this code to be refactoring
        &-tags {
          display: flex !important;
          flex-wrap: wrap;

          & .item {
            width: max-content;
            padding-inline: 15px !important;
          }
        }

        & .item {
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 10px;
          background-color: $light15;
          border-radius: 3px;
          height: 36px;

          & .icon {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;

            & img {
              display: block;
              width: 100%;
            }
          }

          & .text {
            line-height: 16px;
          }
        }
      }
    }

    & .card-content {
      padding: 20px;

      & .custom-select {
        & .select {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  // FORM
  & .form {
    & .label {
      display: inline-flex;
      font-size: 14px;
      padding-bottom: 8px;
      margin-bottom: 0 !important;
      user-select: none;
    }

    select {
      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 18px;
      background-position: 98% center;
    }

    & .select {
      width: 100%;
      height: 50px;
      border: 1px solid #d9d9d9;
      background-color: $white;
      padding-inline: 10px;
      border-radius: 6px;
      outline: 0;
      transition: 0.3s ease all;

      &:hover,
      &:focus {
        border-color: $blue40;
      }
    }

    & .input {
      width: 100%;
      height: 50px;
      border: 1px solid #d9d9d9;
      background-color: $white;
      padding-inline: 10px;
      border-radius: 6px;
      transition: 0.3s ease all;

      &:hover,
      &:focus {
        border-color: $blue40;
      }

      &.error {
        border-color: $red40;
      }
    }

    & .checkbox-group {
      display: flex;
      grid-gap: 30px;
      border: 1px solid #d9d9d9;
      padding-inline: 10px;
      height: 50px;
      border-radius: 6px;
      transition: 0.3s ease all;

      &:hover,
      &:focus {
        border-color: $blue40;
      }

      & .item {
        display: grid;
        grid-template-columns: repeat(2, auto);
        align-items: center;
        grid-gap: 6px;

        & .label {
          padding-bottom: 0;
        }
      }
    }

    & span.label {

      &:hover,
      &:focus {
        & + div {
          border-color: $blue40;
          transition: 0.3s ease all;

          & .input {
            border-color: $blue40;
          }
        }
      }
    }
  }

  & .variations,
  & .attributes {
    display: grid;
    grid-gap: 20px;

    & .form {
      & .form-group {
        margin: 0;

        & .result {
          display: grid;
          min-height: 50px;
          border: 1px solid #d9d9d9;
          border-radius: 6px;
        }
      }

      & .btn {
        margin-top: 27px;
      }
    }
  }

  // CUSTOM SELECT
  & .custom-select {
    position: relative;

    &-remove {
      width: calc(100% - 65px);
    }

    &-add {
      width: calc(100% - 95px);
    }

    & .add-area {
      position: relative;
    }

    & .remove-area {
      width: 50px;
      height: 50px;
      background-color: red;
    }

    // no data
    & .no-data {
      user-select: none;
      display: grid;
      align-items: center;
      color: $dark20;
      padding-left: 10px;
      font-size: 14px;
    }

    // select result
    & .select-result {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 50px;
      padding-inline: 0;
      height: auto;
      min-height: 50px;

      & .select-all {
        position: absolute;
        top: -27px;
        right: 0;

        & .form-group {
          display: flex;
        }
      }

      & .result {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 10px;
        padding: 10px;

        & .item {
          padding: 5px 8px;
          background-color: $light15;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 10px;
          user-select: none;
          cursor: pointer;
          height: 30px;

          & .icon {
            width: 14px;
            height: 14px;
            display: block;

            & img {
              width: 100%;
              display: block;
            }
          }
        }
      }

      & .btn {
        display: grid;
        align-items: center;
        justify-content: center;

        &-remove {
          width: 50px;
          height: 50px;
          position: absolute;
          right: -65px;
          border: 1px solid #d9d9d9;
          border-radius: 6px;
          transition: 0.3s ease all;

          &:focus,
          &:hover {
            border-color: $red40;
          }
        }

        &-add {
          width: 80px;
          height: 50px;
          position: absolute;
          right: -95px;
        }

        & .icon {
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;

          & img {
            display: block;
            max-width: 100%;
          }
        }
      }
    }

    &.error {
      & .select-result {
        border-color: $red40;
      }
    }

    // select area
    & .select-area {
      position: absolute;
      left: 0;
      right: 0;
      height: auto;
      min-height: 50px;
      grid-template-columns: 1fr;
      padding-inline: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateY(0);
      z-index: 10;
      transition: 0.3s ease all;
      background-color: white;

      &.isActive {
        opacity: 1;
        visibility: visible;
        transform: translateY(10px);
      }

      & .result {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 10px;
        padding: 10px;
        min-height: 50px;

        & .item {
          padding: 5px 8px;
          background-color: $light15;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 10px;
          user-select: none;
          cursor: pointer;
          height: 30px;

          &-wic {
            color: #ffdd00;
            background-color: #1967d2;
          }

          &-ebt {
            color: #1967d2;
            background-color: #c2caf2;
          }

          &-fsa {
            color: #009d94;
            background-color: #ccebea;
          }

          & .icon {
            width: 16px;
            height: 16px;
            display: block;

            & img {
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
  }

  // actions
  & .actions {
    margin-top: 20px;
    display: flex;
    grid-gap: 15px;
    justify-content: flex-end;

    & .btn {
      min-width: 176px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-save {
        & .icon {
          width: 20px;
          height: 20px;

          & img {
            display: block;

            &:first-child {
              display: block;
            }

            &:last-child {
              display: none;
            }
          }
        }

        &:hover {
          & .icon {
            & img {
              &:first-child {
                display: none;
              }

              &:last-child {
                display: block;
              }
            }
          }
        }
      }

      & .icon {
        display: inline-flex;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
  }

  & .selected-area {
    display: grid;
    grid-gap: 15px;
    margin-top: 15px;
    padding-top: 15px;
  }

  // Eligible
  & .product-eligible {
    & .select-result {
      grid-template-columns: 1fr 50px !important;

      & .result {
        & .item {
          font-weight: 600;
          font-size: 15px;

          &-wic {
            color: #ffdd00;
            background-color: #1967d2;
          }

          &-ebt {
            color: #1967d2;
            background-color: #c2caf2;
          }

          &-fsa {
            color: #009d94;
            background-color: #ccebea;
          }
        }
      }
    }
  }

  // Product Categories
  & .product-categories {
    & .select-result {
      min-height: 200px;
      max-height: 200px;
      overflow-y: auto;
      grid-template-columns: 1fr;
      border-radius: 6px 0 0 6px;
      min-height: 50px;
    }
  }

  // Product Description
  & .product-description {
    & .select-result {
      overflow-y: auto;
      grid-template-columns: 1fr;
    }
  }
}

// TABS PANEL - change location after created
.tabs__panel {
  position: relative;

  &-header {
    position: relative;
    border-bottom: 1px solid #d9d9d9;

    & ul {
      margin: 0;
      list-style-type: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

      & li {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;
        line-height: 20px;
        border-bottom: 3px solid transparent;
        transition: 0.3s ease all;
        cursor: pointer;

        &:hover,
        &.isActive {
          color: $blue40;
          border-color: $blue40;
        }

        & .icon {
          width: 20px;
          height: 20px;
          display: block;
          border-radius: 50%;
        }

        & .text {
          font-weight: 500;
        }
      }
    }
  }

  &-content {
    padding: 20px;
  }

  & .custom-select {
    & .label {
      color: $dark50;
      font-size: 14px;
      margin-left: 4px;
      padding-bottom: 8px;
      display: block;

      & b {
        font-weight: 500;
      }
    }

    & .select {
      border: 1px solid #d9d9d9;
      border-radius: 6px;

      & .result {
        grid-gap: 10px;
        // display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        display: flex;
        flex-wrap: wrap;
      }

      & .btn {
        width: 50px;
        height: 50px;
      }
    }

    & .selected {
      position: absolute;
      width: 100%;
      padding: 10px 15px;
      bottom: -60px;
      min-height: 100px;
      background-color: white;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      z-index: 10;
    }
  }

  // FORM
  & .form {
    & .form-group {
      position: relative;
    }

    &-variant-price {
      & .form-group {
        margin-bottom: 20px !important;
      }
    }

    &-create {

      &-variations,
      &-attributes {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: 1fr 80px;
      }

      &-units {
        display: grid;
        grid-gap: 15px;

        & .items {
          position: relative;
          display: grid;

          & .select-result {
            display: grid;
            grid-gap: 15px;
            grid-template-columns: 1fr 50px;
            width: 100%;
            min-height: 50px;
            background-color: red !important;

            & .result {
              position: relative;
              display: grid;
              grid-template-columns: 1fr 50px;
              align-items: center;
              padding: 8px 0 8px 15px;
              border: 1px solid #d9d9d9;
              border-radius: 6px;

              & .result-area {
                display: grid;
                grid-gap: 10px;
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

                & .item {
                  height: 32px;
                  border-radius: 4px;
                  background-color: $light15;
                }
              }

              & p {
                margin: 0;
              }

              & .btn {
                margin: 0;
              }
            }

            & .btn-remove {
              margin: 0;
              border: 1px solid #d9d9d9;
              border-radius: 6px;
            }
          }
        }

        & .select-area {
          position: relative;
          top: 0;

          & .result {
            display: grid;
            grid-gap: 10px;
            padding: 10px;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            border: 1px solid #d9d9d9;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.variations {
  & .extend {

    // Actions
    & .actions {
      display: grid;
      justify-content: flex-end;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }

    // Form
    & .form {
      color: $dark40;

      & .form-wrap {
        border-radius: 6px;
        border: 1px solid #d9d9d9;
        margin-bottom: 15px;
        padding: 15px;
      }

      & .separator {
        padding: 0;
        transition: 0.3s padding;

        &.isActive {
          padding-top: 15px;
        }
      }

      & .form-row {
        position: relative;
        display: grid;
        grid-gap: 15px;
        grid-template-columns: 50px 1fr auto;
        align-items: flex-start;
        justify-content: space-between;

        & .form-group {
          &-info {
            & .select-area {
              display: grid;
              grid-gap: 15px;
              grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            }
          }

          &-actions {
            display: flex;
            grid-gap: 15px;

            & .btn {
              width: 50px;
              height: 50px;
              margin-top: 0;
              border: 1px solid #d9d9d9;

              &-expand {
                & .icon {
                  & img {
                    transform: rotate(0);
                    transition: 0.3s ease transform;
                  }
                }

                &.isActive {
                  & .icon {
                    & img {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
          }
        }
      }

      & .form-group {

        & .select,
        & .input {
          color: $dark40;
          height: 50px;
          border-color: #d9d9d9;
          border-radius: 6px;
          padding-inline: 15px;
          background-color: white;
        }
      }

      & .hidden-form {
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: height 0.3s;
        background-color: $light5;
        border-radius: 6px;

        & .block {
          padding: 15px;
          display: grid;
          grid-gap: 20px;

          &-top {
            display: grid;
            grid-gap: 15px;
            align-items: center;
            grid-template-columns: 140px 1fr;

            & .image-area {
              width: 140px;
              height: 140px;
              background-color: $white;
              border: 1px solid #d9d9d9;
              border-radius: 6px;
              overflow: hidden;

              & .img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          &-bottom {
            display: grid;
            grid-gap: 15px;
            grid-template-columns: 1fr 1fr 1fr 120px;
          }
        }
      }

      & .actions {
        display: flex;
        grid-gap: 15px;
        border-bottom: 0;
        justify-content: flex-start;
      }
    }
  }
}

.section__product {
  & .product-save {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    border-top: 1px solid #d9d9d9;
    z-index: 100;

    @media (min-width: 1540px) {
      left: 250px;
    }

    & .actions {
      padding: 10px 30px;
      margin: 0;
    }
  }
}

// grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

.section__product .product__card--gallery {
  & .images-area {
    display: grid;
    grid-gap: 15px;

    & .images-selected {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: repeat(5, 1fr);
      grid-auto-flow: dense;
      grid-auto-rows: minmax(140px, auto);

      & .images {
        position: relative;
        aspect-ratio: 1 / 1;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        overflow: hidden;

        & .img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        & .actions {
          position: absolute;
          inset: 0;
          margin: 0;
          visibility: hidden;
          opacity: 0;
          background-color: rgba(0, 0, 0, 0.2);
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;

          & .btn {
            width: 50px;
            height: 50px;
            min-width: inherit;
            background-color: $white;
            border-radius: 6px;

            & .icon {
              display: block;
              margin: 0;
              width: 18px;
              height: 18px;
              border-radius: 0;
            }
          }
        }

        &:hover {
          & .actions {
            opacity: 1;
            visibility: visible;
          }
        }

        & img {
          width: 100%;
          height: auto;
          display: block;
        }

        &:first-child {
          grid-column: 1 / span 2;
          grid-row: 1 / span 2;
        }
      }
    }
  }

  & .btn-select-images {
    width: 100%;
    height: 100%;
    min-height: 50px;
    border-radius: 6px;
    border: 2px dashed $blue40;
    color: $blue40;
  }
}

.feature-image {
  border: 0.3rem solid $blue40;
}

.legend {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.capsule {
  width: 20px;
  height: 10px;
  border-radius: 5px;
  margin-right: 10px;
  background-color: $blue40;
}

.section__products {
  & .section__header {
    & .dropdown {
      margin: 0;
      position: relative;
      overflow: inherit;

      & .menu {
        position: absolute;
        right: 0;
        width: 240px;
        padding: 10px;
        background-color: $white;
        z-index: 10;
        margin-top: 10px;
        border-radius: 6px;
        border: 1px solid #d9d9d9;

        & .form-group {
          display: flex;
          grid-gap: 10px;
          align-items: center;
          min-height: 40px;
        }
      }
    }

    & .btn {
      &-filter {
        width: 50px;
        height: 50px;

        & span {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
        }
      }
    }
  }
}