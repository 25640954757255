.wicCart{
    border: 1px solid lightgray;
    max-height: 500px;
    overflow-y: auto;

    & .item{
        padding: 12px 24px;
        border-bottom: 1px solid #e6e6e6;
    }
    
    & .product-info{
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        column-gap: 12px;

        & .product-wrapper{
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 12px;

            & .product-image{
                & .image{

                    & img{
                        object-fit: cover;
                    }
                }
            }

            & .product-group{
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                gap: 15px;

                & .product-details{
                    display: flex;
                    justify-content: space-between;
                    gap: 15px;
                }

                & .controls{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
                    justify-content: flex-end;

                    & >input{
                        width: 120px;
                        padding-inline: 10px;
                        height: 40px;
                        border-radius: 4px;
                        border: 1px solid #D9D9D9 !important;
                    }

                    & >select{
                        height: 40px;
                        outline: none;
                        border-radius: 4px;
                        border: 1px solid #D9D9D9;
                        padding-inline: 10px 25px;
                        appearance: none;
                        background: url('../../../public/assets/icons/arrow-dark.svg');
                        background-repeat: no-repeat;
                        background-position: 95% center;
                        background-size: 14px;
                    }

                    & .btn-update{
                        width: max-content;
                        padding-inline: 10px !important;
                        height: 40px;
                        background-color: rgba(255, 165, 0, 0);
                        border-radius: 4px;
                        border: 1px solid rgba(255, 165, 0, 0.7);
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: background-color 0.3s ease, box-shadow 0.3s ease;
                        box-shadow: 0 0 0 3px rgba(255, 165, 0, 0);
                        will-change: background-color, box-shadow;
                        cursor: inherit;
                    }
                }
            }
        }
    }
}