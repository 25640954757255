.page-title {
    &.order-details {
      margin-bottom: 30px;
        & > a {
        display: inline-block;
        margin-right: 15px;
        background-color: transparent;
        border: none;
        color: #636363;
        transition: all .3s;
    }

    & img {
      margin-right: 15px;
      height: 20px;
    }
  }
}

.product-wic-badge-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.section__order-details {
  color: #636363;
  font-size: 20px;

  & .section__header {
    padding-inline: 30px;
    grid-template-columns: 1fr auto auto;

    & .title-details {
      margin-inline: 10px;
      font-size: 18px;

      & .order-date-id {
        color: #4762E6;
        font-weight: 600;
      }
    }
  }

  & .status-details {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    & .status-title {
      margin: 20px;
      font-weight: bold;
    }

    & .status-details-info {
      margin: 30px;

      & .finish {
        font-size: smaller;
      }

      & .failed {
        border: 3px solid red;
        border-radius: 6px;
        padding: 10px 25px;
        margin-right: 30px;
        color: red;
      }

      & .delivered {
        background-color: #4db838;
        padding: 10px 25px;
        color: #fff;
        letter-spacing: 2px;
      }

      & .canceled {
        background-color: rgb(253, 237, 239);
        padding: 10px 25px;
        color: #ed4c5c;
        letter-spacing: 2px;
      }
    }
  }

  & .customer-details {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    & .customer-title {
      margin: 20px;
      font-weight: bold;
    }

    & .customer-details-info {
      display: grid;
      grid-template-columns: 100px 400px;
      align-items: center;
      margin: 30px;

      & label {
        font-size: smaller;
        font-weight: bold;
      }

      & span {
        text-transform: capitalize;
      }
    }
  }

  & .coupon-details {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    & .coupon-title {
      margin: 20px;
      font-weight: bold;
    }

    & .coupon-details-info {
      margin: 30px;
    }
  }

  & .payment-details {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    & .payment-title {
      margin: 20px;
      font-weight: bold;
    }

    & .payment-details-info {
      margin: 30px;
      display: grid;
      grid-template-columns: 400px 200px 300px;
      align-items: center;
      row-gap: 15px;
      width: fit-content;

      & label {
        font-size: smaller;
        font-weight: bold;
      }

      & .paid {
        background-color: #4db838;
        padding: 5px 10px;
        color: #fff;
        letter-spacing: 2px;
      }

      & .not-paid {
        border: 3px solid red;
        border-radius: 6px;
        padding: 5px 10px;
      }

      & .wic-info {
        font-size: smaller;

        & .inverted-wic-card {
          padding: 5px 10px;
          background-color: #ffdd00;
          color: rgb(25, 103, 210);
        }
      }
    }

    & .left-to-pay {
      margin: 30px;
      width: 500px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 30px;
      padding-top: 20px;
      font-size: smaller;
      font-weight: bold;

      & .not-paid {
        border: 3px solid red;
        border-radius: 6px;
        padding: 10px 25px;
      }

      & .paid {
        background-color: #4db838;
        padding: 10px 25px;
        color: #fff;
        letter-spacing: 2px;
      }
    }
  }

  & .product-details {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    & .product-title {
      margin: 20px;
      font-weight: bold;
    }

    & .product-details-info {
      display: grid;
      grid-template-columns: 600px 100px 100px 100px;
      align-items: center;
      row-gap: 10px;
      width: fit-content;
      margin-bottom: 10px;
      padding-bottom: 20px;

      & .title {
        font-size: smaller;
        font-weight: 900;
      }

      & label {
        font-size: smaller;
        font-weight: bold;
      }
    }

    & .table-wrap {
      margin-inline: 30px;
      margin-bottom: 40px;

      & .table {
        font-size: 16px;

        & tr {
          max-width: 100%;
          vertical-align: middle;

          & th {
            min-width: 100px;
          }

          & td {
            min-width: 100px;

            &.total,
            &.quantity-cell,
            &.price-cell {
              width: 150px;
            }

            & .wic-price {
              font-size: smaller;
            }
          }
        }

        & tr th:first-child,
        & tr td:first-child {
          min-width: 50%;
          text-align: left;
        }

        & .subtotal-row,
        .total-shipping-row,
        .total-row {
          font-size: 20px;
          font-weight: 600;
          background-color: #fff;

          & td {
            text-align: right !important;
            border-bottom-width: 0px;
          }
        }

        & .total-row {
          & span {
            border-top: 1px solid black;
            padding-left: 40px;
            padding-top: 10px;
          }
        }
      }

      & .no-products {}
    }
  }

  & .shipping-details {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    color: #333;

    & .shipping-title {
      margin: 20px;
      font-weight: bold;
    }

    & .shipping-details-info {
      margin: 30px;
      margin-bottom: 10px;
      padding-bottom: 20px;

      & .address-product-wrap {
        margin-bottom: 30px;

        & .address-info {
          height: 80px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          padding: 10px 25px;
          background-color: rgba(0, 0, 0, 0.05);

          & .address,
          & .address-shipping-info {
            font-size: 16px;

            & label {
              font-weight: 900;
            }

            & span {
              // text-transform: capitalize;
              // font-weight: 600;
            }
          }

          & .address-shipping-info {
            & span {
              margin-right: 20px;
            }
          }

          & .carrier {
            font-size: 16px;
            font-style: italic;
            font-weight: 600;
          }
        }

        & .product-list-wrap {
          display: flex;
          // grid-template-columns: 1fr 300px;
          justify-content: space-between;
          align-items: flex-start;
          row-gap: 10px;

          & .product-info {
            margin-bottom: 5px;
            padding-left: 30px;
            display: grid;
            grid-template-columns: 60px 1fr;
            column-gap: 10px;
            align-items: center;
            font-size: 16px;

            & .quantity {
              text-align: right;
            }
          }

          & .postage-label {
            cursor: pointer;
          }
        }
      }

      & .benjamin-shipping-price {
        font-size: smaller;
        margin-bottom: 5px;

        & label {
          font-weight: 600;
        }
      }
    }

    & .no-info {
      margin: 30px;
      margin-bottom: 10px;
      padding-bottom: 20px;
      font-size: 16px;
    }
  }

  & .order-comment {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    & .comment-title {
      margin: 20px;
      font-weight: bold;
    }

    & .comment-info {
      margin: 30px;
      margin-bottom: 10px;
      padding-bottom: 20px;
      font-size: 16px;
      color: #333;
    }
  }
}