// BUTTON
.btn {
  position: relative;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: $black;
  cursor: pointer;

  // BUTTON WIDTH FULL
  &-block {
    width: 100%;
    display: block;
  }

  // BUTTON DISABLED
  &[disabled] {
    color: $dark20;
    border-color: $dark20;
    opacity: 0.75;
    pointer-events: auto;
    cursor: not-allowed;

    &.pdf {
      cursor: wait;
    }
  }

  // BUTTON TRANSITION
  &-info,
  &-primary,
  &-primary-outline,
  &-danger-outline {
    transition: all 0.3s ease;
    box-shadow: inset 3px 3px 4px rgba(255, 255, 255, 0.1), inset -3px -3px 3px rgba(0, 0, 0, 0.05);
  }

  // BUTTON DEFAULT
  &-info,
  &-default,
  &-primary,
  &-danger-outline,
  &-primary-outline,
  &-primary-link,
  &-danger-link,
  &-primary-disabled,
  &-danger-disabled,
  &-primary-outline-disabled,
  &-danger-outline-disabled {
    height: 50px;
    border-radius: 6px;
    border-width: 2px;
    border-style: solid;
    font-weight: $fontWeight600;
    outline: 0 !important;
  }

  &-default {
    border: 1px solid $light20;
  }

  &-primary {
    color: $white;
    background-color: $blue40;
    border-color: $blue40;

    &:hover,
    &:focus,
    &:active {
      color: $blue40;
      border-color: $blue40;
      background-color: $white;
    }

    &.btn-no-focus:focus {
      color: $white !important;
      background-color: $blue40 !important;
      border-color: $blue40 !important;
    }
  }

  &-primary-disabled {
    color: $white !important;
    background-color: $blue40 !important;
    border-color: $blue40 !important;
  }

  &-danger-disabled {
    color: $white !important;
    background-color: $red40 !important;
    border-color: $red40 !important;
  }

  &-primary-outline-disabled {
    color: $dark20;
    border-color: $dark20;
    opacity: 0.75;

    &:hover,
    &:focus,
    &:active {
      color: $dark20 !important;
      border-color: $dark20 !important;
      opacity: 0.75 !important;
    }
  }

  &-danger-outline-disabled {
    color: $dark20;
    border-color: $dark20;
    opacity: 0.75;

    &:hover,
    &:focus,
    &:active {
      color: $dark20 !important;
      border-color: $dark20 !important;
      opacity: 0.75 !important;
    }
  }

  &-primary-outline {
    color: $blue40;
    background-color: $white;
    border-color: $blue40;

    &:hover {
      color: $dark40 !important;
      border-color: $dark40 !important;
      background-color: $white !important;
    }
  }

  &-danger-outline {
    color: $red40;
    background-color: $white;
    border-color: $red40;

    &:hover,
    &:focus,
    &:active {
      color: $dark40 !important;
      border-color: $dark40 !important;
      background-color: $white !important;
    }
  }

  &-primary-link {
    color: $blue40;
    background-color: $white;
    border-color: $white;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      color: $dark60;
      background-color: $white;
      border-color: $white;
    }

    &.btn-no-focus:focus {
      color: $white !important;
      background-color: $blue40 !important;
      border-color: $blue40 !important;
    }
  }

  &-danger-link {
    color: $red40;
    background-color: $white;
    border-color: $white;
    box-shadow: none;

    &:hover,
    &:focus {
      color: $dark60;
      background-color: $white;
      border-color: $white;
    }
  }

  &-download {
    padding: 8px 16px;
    display: grid;
    grid-template-columns: 16px 1fr;
    column-gap: 16px;

    & .icon {
      display: flex;
      align-items: center;
      width: 16px;
      height: 100%;

      & img {
        display: block;
      }
    }

    & .text {
      color: $blue40;
      font-weight: $fontWeight400;
    }
  }

  &-info {
    color: $white;
    border-color: #17a2b8;
    background-color: #17a2b8;
  }

  // CATEGORIES
  &-categories {
    background-color: $white;
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $light30;
    border-radius: 4px;

    & .icon {
      width: 16px;
      height: 16px;

      & img {
        width: 100%;
        display: block;
      }
    }
  }
}

// LINK
.link {
  text-decoration: none;
  font-weight: $fontWeight600;

  &-primary {
    color: $blue40;

    &:hover {
      color: $blue60;
    }
  }
}