.section__blogs {

  & .container {
    padding: 24px;
    display: flex;
    gap: 24px;

    & .left_side {
      flex: 2;

      & .tox {
        border: 1px solid #C2C2C2 !important;
      }
    }

    & .right_side {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 16px;
      padding-bottom: 0;

      & .row {
        color: black;
        display: flex;
        flex-direction: column;
        gap: 6px;
        position: relative;

        &.actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          gap: 10px;

          &>div {
            width: 100%;

            & a,
            & button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
            }
          }
        }

        & .image_upload_container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          overflow: hidden;
          height: 80px;
          padding: 8px;

          &.cursor {
            cursor: pointer;
          }

          & .image_preview {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            & img {
              height: 64px;
              width: 64px;
              object-fit: cover;
              object-position: center;
            }

            & .remove_image {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0, -50%);
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all .15s ease;
              cursor: pointer;

              & .trash_can {
                color: black;
                font-size: 30px;
              }
            }
          }

          & .image_icon {
            color: #636363;
            font-size: 30px;
            user-select: none;
            pointer-events: none;

            @media(max-width: 1024px) {
              font-size: 22px;
            }
          }

          & .image_name {
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            color: #636363;
            user-select: none;
            pointer-events: none;

            @media(max-width: 1024px) {
              font-size: 16px;
            }
          }
        }

        & .show_tags {
          display: flex;
          gap: 10px;
          align-items: center;
          flex-wrap: wrap;

          & .tag_button {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            gap: 6px;
            border-radius: 4px;
            height: unset;
            box-shadow: none;
            cursor: pointer;

            & span {
              border-radius: 4px;
              font-size: 13px;
              font-weight: 500;
            }

            & .tag_btn_x {
              display: flex;
              align-items: center;
              justify-content: center;
            }

          }

        }

        & .tags {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          & .blog_tag {
            height: 100%;
            padding: 0px 10px;
            border-radius: 6px;
            font-weight: 500;
          }

          &>:nth-child(1) {
            width: 100%;
          }

          &>:nth-child(2) {
            height: 100%;
          }
        }

        & input,
        & select,
        & .row_select {
          width: 100%;
          padding: 8px 16px;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          outline: none;
          min-height: 38.8px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 5px;

          & .tag_button {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            gap: 6px;
            border-radius: 4px;
            height: unset;
            box-shadow: none;
            cursor: pointer;

            & span {
              border-radius: 4px;
              font-size: 13px;
              font-weight: 500;
            }

            & .tag_btn_x {
              display: flex;
              align-items: center;
              justify-content: center;
            }

          }

          &:focus {
            border-color: $blue40;
          }
        }

        & .row_select {
          position: relative;
        }

        & .select_container {
          position: relative;

          & .blog_dropdown {
            position: absolute;
            top: 120%;
            left: 0;
            z-index: 1000;
            background-color: white;
            border: 1px solid #C2C2C2;
            display: flex;
            flex-direction: column;
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
            max-height: 200px;
            overflow-y: auto;
            cursor: pointer;

            & .category_with_checkbox {
              display: flex;
              align-items: center;
              gap: 7px;

              & .category_checkbox {
                width: 18px;
                height: 18px;
                border: 1px solid lightgray;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;

                &.checked {
                  border-color: $blue10;
                }

                & .check_mark {
                  color: $blue50;
                }
              }
            }

            @media(max-height: 1024px) {
              top: unset;
              bottom: 120%;
              max-height: 300px;
            }

            & div {
              padding: 10px;

              &:hover {
                background-color: #f4f4f4;
              }
            }
          }

          & select {
            appearance: none;
          }

          & .arrow_down {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translate(0, -50%);
            font-size: 20px;
            pointer-events: none;
            user-select: none;
          }
        }

      }
    }
  }
}