.form-wrapper {
  width: 100%;
  max-width: 640px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  padding: 55px 45px;
  background-color: $white;
  margin-left: auto;
  margin-right: auto;

  & h1 {
    font-size: 36px;
    line-height: 36px;
  }

  & h3 {
    color: $light50;
    text-align: center;
  }

  & .we-sent {
    text-align: center;
    margin-bottom: 46px;
  }

  & form {

    & .subtitle {
      display: block;
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
    }
    
    & > .label {
      display: block;
      margin-bottom: $gapBase;
      font-weight: $fontWeight600;
      color: $dark20;
    }

    & input {
      border-radius: 6px;
      height: 46px;
      padding: 0 $gapDefault;
    }

    & .ant-input-affix-wrapper {
      padding: 0;
      border-radius: 6px;

      & input {
        padding: 0 $gapDefault;
        border-radius: 6px;
      }
    }

    & .ant-input-suffix {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 46px;
      height: 46px;
      justify-content: center;
    }
  }
}




input {
  transition: all 0.3s;
  border: 1px solid #d9d9d9;

  &:hover {
    border-color: $blue40;
  }

  &:focus {
    border-color: $blue40;
    outline: 0;
  }
}

input[type=checkbox] {
  width: 16px;
  height: 16px !important;
  display: flex;
}

.error__onblur {
  position: absolute;
  left: 0;
  bottom: -10px;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  color: #ff4d4f;
  line-height: 20px;
  font-weight: 500;
  transition: all 0.3s;

  &.error {
    bottom: -20px;
    opacity: 1;
    visibility: visible;
  }
}

.hr {
  border-color: #D9D9D9 !important;
  opacity: 0.3;
}

label {
  color: #636363
}

.form {
  .initial-height {
    .ant-form-item-control-input-content {
      height: auto;
    }
  }
}

form .ant-form-item.initial-height .ant-form-item-control-input-content {
  height: 24px !important;
  min-height: auto !important;
}

input[type=checkbox] {
  accent-color: $blue40 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $blue40;
  border-color: $blue40;
}

.fake-checkbox {
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #767676;
  border-radius: 3px;
  background-color: $white;
}

.fake-checkbox.checked {
  background-color: $blue40;
  border-color: $blue40;
  // background-image: url('../../../public/assets/icons/checkbox_check.svg');
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgBjZFREYAgEESJQAQi0EAjGMEG2gAbGMEoRCECEU5uxJkdcGR35n7u7sEuGENKRHypwO4r4EoleeRZ6AVUjgEOAMb2ytIKwMUAmiNXQO1ZHO516KFnIUfucui17WnQU21fNmZYiE3w889/kF7JEMEjAkL+B4ZfDKv6zNNo7wbrjun0PL6aHAAAAABJRU5ErkJggg==');
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: center;
}

.checkbox-rows {
  & .row-checkbox {
    display: flex;
    align-items: center; 
    column-gap: 10px; 
    margin-bottom: 10px;

    & label {
      text-transform: capitalize;
    }
  }

  & .checkbox-cols {
    align-items: center;
    margin-left: 48px;
    column-gap: 16px;

    & .checkbox-permission {
      display: flex;
      align-items: center;
      column-gap: 10px;

      & label {
        text-transform: capitalize;
      }
    }
  }
}

.select-all-checkbox {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.form-create-category {
  & .select {
    & .ant-select-selection-item {

      line-height: 22px !important;
    }

    & .ant-select-selector {
      padding: 0 !important;
    }

    & .ant-select-selection-overflow {
      padding: 0 10px;
    }

    & .ant-select-selection-placeholder {
      left: 15px;
      right: 15px;
    }
  }

  & .avatar-uploader {
    & .ant-upload {
      margin: 0;
      border-radius: 6px;
    }
  }
}

.input-full {
  width: 100%;
}


.img-responsive {
  width: 100%;
  max-width: 100px;
  display: block;
} 


// FORM GROUP
.form-group {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  // BUTTON SIDEBAR
  &-sidebar {
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
  }

  // BUTTON MODAL 
  &-modal {
    display: flex;
    justify-content: center;
    column-gap: 16px;
  }

  // BUTTON 
  &-sidebar,
  &-modal {
    & .btn {
      width: 100%;
      min-width: 140px;
      max-width: 180px;
    }
  }

  // INPUT 
  & .input {
    width: 100%;
  }

  // TEXTAREA 
  & .input-textarea {
    border: 1px solid $light20;
    border-radius: 6px;
    min-height: 100px;
    outline: 0;
    padding: 16px; 
  }
}

.upload-image {
  & .avatar {
    position: relative;
    display: grid;
    row-gap: 20px;

    & .image-wrapper {
      & .img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border: 1px solid #ccc;
        border-radius: 6px;
        display: block;
      }
    }
  }

  & .upload {
    position: relative;
    display: grid;
    row-gap: 20px;

    & .validation-image {
      position: relative;

      & p {
        opacity: 0;
        visibility: hidden;
        height: 0;
        color: #ff4d4f;
        line-height: 20px;
        font-weight: $fontWeight500;
        margin-bottom: 10px;
        transition: all 0.3s ease;

        &.error {
          opacity: 1;
          visibility: visible;
          height: auto;
        }
      }
    }

    & .upload-wrapper {
      position: relative;
      touch-action: none;
      user-select: none;
      pointer-events: none;

      & .input-upload {
        border: 2px dashed $light15 !important;
        user-select: none;
        touch-action: none;
        pointer-events: none;
      }

      & .text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: -1;
      }
    }

    & .input-upload {
      width: 100px;
      height: 100px !important;
      outline: 0 !important;
      padding: 30px 3px !important;
      color: transparent;



      &:focus {
        outline: 0 !important;
      }
    }

    & .label {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 16px;

      &.btn-no-hover {
        &:hover {
          border-color: $blue40 !important;
          color: $blue40 !important;
          opacity: 0.75;
        }
      }

      & .icon {
        display: block;
        width: 16px;
      }

      & .text {
        font-size: 17px;
      }
    }
  }
}

.ant-form {
  & .select {
    outline: 0;
    display: block;
    width: 100%;
    font-size: 16px;
    padding: 0.8rem 0.5rem;
    border: 1px solid #333;
    font-family: inherit;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-size: 16px;
    transition: border-color 0.3s;

    &:hover {
      border-color: $blue40;
    }

    &-full {
      width: 100%;
      background-color: $white;
      border-color: $light20;
      padding: 0 20px;
    }
  }

  & .ant-form-item:not(:last-child) {
    & .ant-row {
      flex-direction: column !important;
      
      & .ant-form-item-label {
        text-align: left;
        font-weight: $fontWeight500;
        color: $light40;
      } 
    }

    & .checkbox-cols {
      flex-direction: row !important;
    }
  }
}

form {
  & .label {
    position: relative;

    & b {
      position: relative;
      font-weight: $fontWeight500;
      color: rgba(0, 0, 0, 0.85);
      margin-left: 4px;

      &.required {
        &:before {
          margin-right: 4px;
          font-size: 14px;
          font-family: 'SimSun', sans-serif;
          line-height: 1;
          display: inline-block;
          content: '*';
          clear: both;
          color: #ff4d4f;
        }
      }
    }
  }
}

input[type=file]::file-selector-button {
  display: none;
}

input[type=file]::-webkit-file-upload-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: -100%;
  background-color: transparent;
  color: transparent;
}

input[type=file]::-ms-browse {
  display: none;
}

.ant-form {
  & .form-group-units,
  & .form-group-attributes,
  & .form-group-address {
    display: grid;

    & .btn-default {
      & .icon {
        display: block;
      }
    }

    & .units,
    & .attributes,
    & .addresses {
      min-height: 50px;
      grid-gap: 8px;
      border: 1px solid $light15;
      border-radius: 6px;
      padding: 7px 10px;
      transition: border ease 0.3s;
      // display: grid;
      // grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
      display: flex;
      flex-wrap: wrap;
      
      &:hover {
        border-color: $blue40;
      }

      & .unit-item,
      & .attribute-item {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        padding: 12px;
        background-color: $light20;
        // text-transform: uppercase;
        border-radius: 4px;
        height: 34px;
        cursor: pointer;

        & .icon {
          width: 12px;
          height: 12px;
          display: block;

          & img {
            display: block;
            width: 100%;
          }
        }
      }

      & .units-area,
      & .attributes-area {
        margin: 0;
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: $light60;
        padding-left: 6px;
      }
    }
  }
}

.fake-label {
  display: block;
  height: 32px;
  & b {
    font-weight: $fontWeight500;
    margin-left: 2px;

    &.required {
    }
  }
}

.search-wrapper {
  position: relative;
  width: 100%;
  height: 1px;

  & .search-attribute {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    min-height: 200px;
    background-color: $white;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.01), 0 6px 16px 0 rgba(0,0,0,.03), 0 9px 28px 8px rgba(0,0,0,.02);
    border-radius: 6px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    transition: all 0.3s ease;
    z-index: -1;

    &.isActive {
      opacity: 1;
      visibility: visible;
      bottom: -20px;
      z-index: 10;
    }

    & .search-area {
      width: 100%;
      height: 50px;

      & .form-group {
        border-bottom: 1px solid $light10;
      }

      & .input {
        border: 0;
        border-radius: 0;
        background-color: $white;
      }
    }

    & .content-area {
      width: 100%;
      height: calc(100% - 50px);
      overflow-y: auto;
      padding: 20px;
      display: flex;
      grid-row-gap: 12px;
      row-gap: 12px;
      align-items: flex-start;
      flex-direction: column;

      & .item {
        display: block;
        width: 100%;
        padding: 8px 16px;
        background-color: $light5;
        border: 1px solid $light10;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .01), 0 6px 16px 0 rgba(0, 0, 0, .03), 0 9px 28px 8px rgba(0, 0, 0, .05);
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }
}

.form-group {
  opacity: 1;
  transition: opacity 0.3s ease;

  &.isHide {
    opacity: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 102;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  &.isActive {
    opacity: 1;
    visibility: visible;
  }
}

.form-select {
  position: relative;
  z-index: 103;

  & .attributes,
  & button {
    background-color: $white;
    transition: all 0.3s ease;
  }
}

/* CLEAR */
.dropdown-select {
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  color: black;
  border: 1px solid rgba(0, 0, 0, .2);
  align-items: center;

  & .list-area {
    padding: 8px 16px;
  }

  & .search-area {
    width: 50px;
    height: 50px;
    background-color: #ccc;
  }
}

.w-full {
  width: 100%;
}

/* RECREATE */
.form-select {
  position: relative;
  width: 100%;

  & .wrapper-select {
    position: relative;
    width: 100%;
    height: 1px;
  }

  & .form-select-input {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    overflow-y: hidden;
    opacity: 0;
    visibility: hidden;
    background-color: $white;
    transition: all 0.3s ease;

    &.isActive {
      opacity: 1;
      visibility: visible;
      bottom: 10px;
    }

    & .input {
      height: 50px;
      border: 0;
      border-bottom: 1px solid $light10;
      border-radius: 0 !important;
      padding: 0 16px;
      color: $dark50;
      background-color: $white;
      overflow: hidden;
    }

    & .list-items {
      width: 100%;
      height: calc(100% - 50px);
      overflow-y: auto;
      padding: 20px;
      display: flex;
      grid-row-gap: 12px;
      row-gap: 12px;
      align-items: flex-start;
      flex-direction: column;

      & .item {
        display: grid;
        align-items: center;
        height: 40px;
        line-height: 40px;
        width: 100%;
        padding: 0 16px;
        color: $dark50;
        background-color: $light10;
        border-radius: 6px;
        user-select: none;
        cursor: pointer;
      }
    }
  }

  & .form-select-output {
    display: grid;
    grid-template-columns: 1fr 50px;
    column-gap: 16px;
    border-radius: 6px;

    & .result {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(max-content, 100px));
      padding: 8px 16px;
      grid-gap: 8px;
      min-height: 50px;
      border: 1px solid #d9d9d9;
      border-radius: 6px;

      & p {
        margin: 0;
        margin-left: 3px;
        line-height: 32px;
      }

      & .badge {
        font-weight: 400;
        background-color: $light10;
        user-select: none;
        display: flex;
        align-items: center;
        column-gap: 8px;
        height: 32px;
        cursor: pointer;

        &::before {
          content: inherit;
        }

        & .icon {
          width: 12px;
          height: 12px;

          & img {
            display: block;
            width: 100%;
            margin-top: 1px;
          }
        }

        & .text {
          color: $dark50;
        }
      }
    }

    & .btn {
      height: 50px;
      border-radius: 6px; 
      border: 1px solid #d9d9d9;
    }
  }
}


.form-group {
  & .password-visibility {
    & .input {
      padding-right: 65px;
    }
    & .btn {
      position: absolute;
      top: 1px;
      right: 1px;
      width: 48px;
      height: 48px;
      border-radius: 0;
      background-color: transparent;
    }
  }

  & .check-password {
    position: absolute;
    bottom: 70px;
    right: 0;
    left: 0;
    width: 100%;
    height: 160px;
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
    padding: 16px;
    display: grid;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    &.isActive {
      opacity: 1;
      visibility: visible;
      bottom: 90px;
    }

    & .error-text {
      position: relative;
      color: $red40;
      font-size: 16px;
      margin: 0;
      font-weight: 600;
      padding-left: 15px;

      &::before {
        content: '';
        clear: both;
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 10px;
        background-color: $red40;
        border-radius: 50%;
      }
    }

    & .success-text {
      position: relative;
      color: hsl(130deg, 100%, 31%);
      font-size: 16px;
      margin: 0;
      font-weight: 600;
      padding-left: 15px;

      &::before {
        content: '';
        clear: both;
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 10px;
        background-color: hsl(130deg, 100%, 31%);
        border-radius: 50%;
      }
    }    
  }
}

.section__customers {
  & .tbody-actions-address {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  & .addresses {
    display: grid;
    row-gap: 10px;

    & .item {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: $light10;
      padding: 10px 15px;
      border-radius: 6px;
    }
  }
}

.ant-popover-content {
  & .addresses {
    display: grid;
    row-gap: 10px;

    & .item {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: $light10;
      padding: 10px;
      border-radius: 6px;
    }
  }
}

.toggle-new-password {
  display: flex;
  align-items: center;
  column-gap: 8px;

  & .input-checkbox[type=checkbox] {
    border: 1px solid #d9d9d9 !important;
    // opacity: 0.5;
  }

  & label {
    user-select: none;
  }
}

.fake-select {
  width: 100%;
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  display: grid;
  align-items: center;
  padding: 0 15px;
  transition: border-color ease 0.3s;



  &:hover {
    border-color: $blue40;
  }

  & .result,
  & .dropdown {
    & p {
      color: $dark20;
      margin: 0;
      text-align: left;
      display: block;
      width: 100%;
      height: 48px;
      line-height: 48px;
    }

    & .badge {
      text-align: center;

      &-ebt {
        color: #1967D2;
        background-color: #C2CAF2;
      }

      &-wic {
        color: #FFDD00;
        background-color: #1967D2;
      }

      &-fsa {
        color: #009D94;
        background-color: #CCEBEA;
      }
    }
  }

  & .area {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 10px;
    width: calc(100% - 50px);
  }

  & .result {
    display: flex;
    column-gap: 8px;
    
    & span {
      padding: 12px;
      font-size: 18px;
      width: 69px;
      text-align: center;
      text-transform: uppercase;
      justify-content: center;

      &::before {
        content: inherit;
      }
    }

    & .btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50px;
      height: 50px;
      
      & .icon {
        margin: auto;
        width: 17px;
        height: 17px;
        padding: 0;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      }
    }
  }

  & .dropdown {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $white;
    height: auto;
    z-index: 100;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 15px;
    display: none;
    grid-gap: 8px;
    z-index: 100;
    

    &.isActive {
      display: flex;
    }

    & span {
      display: block;
      color: black;
      width: 100px;
      font-size: 18px;
      padding: 4px;
      text-transform: uppercase;
      user-select: none;

      &::before {
        content: inherit;
      }
    }
  }
}

.form-group-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;

  & .input {
    width: auto;
  }

  & .label {
    margin: 0;
    user-select: none;
  }
}

.form-panel {
  padding: 15px;
}

.form-attributes {
  & .fake-select {
    & .result,
    & .dropdown {
      & .badge {
        display: flex;
        width: auto;
        color: $black;
        background-color: $light15;
        font-size: 14px;
        font-weight: 400;

        & .text {
          padding: 0 10px;
          font-size: 12px;
        }
      }
    }

    & .result {
      & .badge {
        grid-gap: 5px;

        & > .icon {
          width: 16px;
          height: 16px;
          display: block;
          padding: 2px;

          & img {
            display: block;
            width: 100%;
          }
        }

        & > .text {
          padding: 0;
          width: auto;
        }
      }
    }
  }
}