.create__order > .create__table{
    & .upload-wic-card {
        border-top: 1px solid #D9D9D9;
        padding: 12px 24px;
        background-color: $light5;
        border-radius: 0 0 8px 8px;
        display: block;

        & .upload-wic-checkbox {
            display: grid;
            align-items: center;
            justify-content: flex-start;
            grid-template-columns: 16px auto;
            grid-gap: 15px;

            & input[type="checkbox"] {
                height: auto;
                width: 16px;
                height: 16px;
            }

            & label {
                user-select: none;
            }
        }

        & .upload-wic-wrapper {
            width: 100%;
            border-top: 1px solid #D9D9D9;
            margin-top: 15px;
            padding-top: 15px;

            & .image-upload-grid {
                display: flex;
                grid-gap: 15px;
                justify-content: flex-start;
            }
        }

        & .img-box {
            padding: 5px;

            &>span {
                display: block !important;
            }
        }

        & .info {
            padding: 0;
            & p{
                margin: 0;
            }
        }

        & .upload-header {
            & .btn {
                padding: 10px 24px;
                border-radius: 3px;
                font-size: 16px;
                background-color: hsl(45deg, 100%, 51%);
                border-color: hsl(45deg, 100%, 51%);
                color: hsl(0deg, 0%, 0%);
                display: block;
                text-align: center;
                text-wrap: balance;
            }
        }
    }
}

.upload-image {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    justify-content: flex-start;

    & label {
        margin-bottom: 0;
    }

    & input[type="file"] {
        height: auto;
        display: none;
    }

    & .btn {
        border-radius: 4px;
    }

    & .btn-remove {
        padding: 0;
        width: 50px;
        height: 50px;
        box-shadow: 0 0 0 3px rgba(237, 74, 90, 0);
    }

    & .info {
        padding: 15px;
        line-height: 28px;
    }

    & .upload-preview {
        & figure {
            position: relative;
            width: 300px;
            height: auto;
            background-color: $white;
            border-radius: 4px;
            overflow: hidden;
            border: 1px solid #d9d9d9;
            // box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);

            & img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            & .remove-image {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                visibility: hidden;
                background-color: rgba(0, 0, 0, .3);
                transition: opacity 0.3s ease, visibility 0.3s ease, background-color 0.3s ease;
                will-change: opacity, visibility, background-color;
                display: flex;
                align-items: center;
                justify-content: center;

                & .btn-remove{
                    padding: 15px;
                    background-color: white;
                }
            }

            &:hover {
                & .remove-image {
                    opacity: 1;
                    visibility: visible;

                    & .btn-remove{
                        background-color: white;
                        padding: 15px;
                    }
                }
            }
        }
    }

    & .upload-header {}
}
