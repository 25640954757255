.section__delivery {
    & .section__content {
        display: grid;
        grid-template-columns: 280px 1fr 360px;
        padding: 20px;
        color: #333;
        min-height: 100%;

        & .item {
            height: 100%;
            padding: 20px;

            &:first-child {
                border-right: 1px solid #d9d9d9;

            }

            &:last-child {
                border-left: 1px solid #d9d9d9;
            }

            & > div > header {
                padding-bottom: 30px;
                margin-bottom: 30px;
                border-bottom: 1px solid #d9d9d9;

                & h3 {
                    margin-bottom: 0;
                }
            }

            & .group {
                display: grid;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                &-actions {
                    display: flex;
                    gap: 20px;

                    & .btn {
                        width: 100%;
                    }
                }

                & label {
                    margin-bottom: 7px;
                }

                & select {
                    min-width: 239px;
                    height: 50px;
                    padding: 0 15px;
                    border-radius: 6px;
                    background-color: #fff;
                    box-sizing: border-box;
                    margin: 0;
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 14px;
                    font-variant: tabular-nums;
                    line-height: 1.5715;
                    list-style: none;
                    font-feature-settings: "tnum", "tnum", "tnum";
                    border: 1px solid #d9d9d9;
                    max-width: 300px;
                    padding: 0 10px;
                    outline: 0;
                    line-height: 1;
                    border-radius: 5px;
                    background-repeat: no-repeat;
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAqElEQVR42r1QSwqEMAwNiIsiGEFEKlhFR7AL24UH8Ag9gkfOUXKEDOM4Mh9n63uLJI8X8gG4AnOY8VTHOQCAW7w4mn4sEzry4hawbMWKpfHDMqKlTWcY1ps82FN3WDrs6akOKwC0wbARI4bqzVKjoa3mNuwdldesRYvm0pdHXvm3mYXPOZdccn7Fwn9tnTUppbKTsubk7gQVKVGiKME/L4sxoohihGtxB2vkQp9V9ml2AAAAAElFTkSuQmCC);
                    background-position-y: center;
                    background-position-x: 90%;
                    background-size: 12px;
                    appearance: none;
                    transition: all 0.3s;
                }
            }

            & .elements {
                display: flex;
                justify-content: flex-start;
            }

            & .counter {
                width: 100%;
                max-width: 102px;
                height: 50px;
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                display: grid;
                overflow: hidden;

                & .input-value {
                    border: 0;
                    width: 100%;
                    padding: 0 10px;
                }
            }

            & .block-day {
                display: flex;
                flex-wrap: wrap;
                grid-gap: 15px;
                justify-content: flex-start;

                & .title {
                    color: #666;
                }
            }

            & .slots-results {
                display: grid;
                grid-gap: 20px;

                &.border {
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #d9d9d9;
                }

                & .slot {
                    display: flex;
                    align-items: center;
                    grid-gap: 15px;

                    &-cell {
                        width: 100%;
                        min-height: 50px;
                    }
                }

                & .date-time {
                    display: flex;
                    align-items: center;
                    grid-gap: 15px;

                    & .input {
                        width: 100%;
                        border-color: #d9d9d9;
                        min-height: 50px;
                        margin: 0;
                        border-radius: 5px;
                        padding-inline: 10px;
                    }

                    & .separator {
                        width: 20px;
                        display: block;
                    }
                }
            }
        }

        & .override-list {

            & ul {
                list-style-type: none;

                & li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    grid-gap: 10px;
                    padding: 10px;
                    background-color: white;
                    border-radius: 5px;

                    &:first-child {
                        border-top: 1px solid #d9d9d9;
                        padding-top: 10px;
                        margin-top: 20px;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid #d9d9d9;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                    }

                    & .value {
                        padding: 5px;
                        display: inline-flex;
                        background-color: #f2f2f2;
                        border-radius: 5px;
                        margin-left: 5px;
                    }

                    & p {
                        margin-bottom: 0;
                    }

                    & .btn-danger-outline {
                        padding-inline: 10px;
                    }
                }
            }
        }

        & .btn-danger-outline {
            min-width: 50px;
        }
    }
}

.range-picker {
    width: 100%;
    border-radius: 5px;
    min-height: 50px;
}

.ant-picker-panels {
    flex-direction: column;
    width: 319px;

    & .ant-picker-date-panel {
        width: 100%;

        & table {
            width: 100%;
        }
    }
}