.section__attributes {
  & .table {
    & .actions {
      display: flex;
      column-gap: 16px;
      justify-items: center;

      & .btn-actions {
        border: 0;
        padding: 0;
        width: 30px;
        height: 30px;
        border: 1px solid #D9D9D9;
        display: grid;
        justify-content: center;
        justify-items: center;
        align-content: center;
        border-radius: 5px;

        & .icon {
          width: 16px;
          height: 16px;
          display: block;

          & img {
            display: block;
            width: 100%;
          }
        }
      }
    }

    // This css use if not using bulk delete actions 
    & .thead,
    & .tbody {
      & tr {
        & th,
        & td {
          padding: 16px 60px;
          &:first-child {
            text-align: left;
            width: 1% !important;
            min-width: 200px !important;
          }

          &:last-child {
            text-align: left;
            width: 1% !important;
            min-width: 200px !important;
          }          
        }
      }
    }

    // Badge
    .badge {
      &-silver {
        display: flex;
        align-items: center;
        background-color: $light10;

        &::before {
          content: '';
          width: 0;
          height: 0;
          display: none;
        }

        & .text {
          font-weight: $fontWeight400;
          color: $dark50;
          margin-top: 2px;
        }
      }
    }
  }


  & .ant-form {
    & .form-group-units {
      display: grid;
      grid-template-columns: 1fr 50px;
      column-gap: 16px;
    }
  }
}