.toggle-switch {
    display: flex;
    gap: 5px;
    align-items: center;
    margin: 10px 0px;

    input[type=checkbox] {
        position: relative;
        width: 32px;
        height: 14px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        border-radius: 2rem;
        background-color: hsl(0deg, 0%, 70%);
        box-shadow: inset 0 0 5px #b3b3b3;
        transition: all 0.3s ease;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: -2.59px;
            left: 0;
            transform: translateX(-10%);
            background-color: $white;
            border: 1px solid $light40;
            box-shadow: 0px 0px 6px rgba($color: black, $alpha: .3);
            border-radius: 50%;
            width: 20px;
            height: 20px;
            transition: all 0.3s ease;
        }
    }

    .checkbox:checked {
        background-color: rgba(179, 179, 179, 0.5);
        box-shadow: inset 0 0 5px rgba(179, 179, 179, 0.5);
        transition: all 0.3s ease;
        outline: none;

        &::before {
            content: '';
            position: absolute;
            top: -2.59px;
            left: 0;
            transform: translateX(60%);
            background-color: var(--official-color);
            border: 1px solid $light50;
            box-shadow: 0px 0px 6px rgba($color: black, $alpha: .3);
            border-radius: 50%;
            width: 20px;
            height: 20px;
            transition: all 0.3s ease;
        }
    }
}