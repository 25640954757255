.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px;

  &-relative {
    position: relative !important;
  }

  &-full {
    width: 100%;
    border-radius: 10px;
    min-height: calc(100vh - 180px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  & .loading-container {
    width: 182px;
    text-align: center;
    padding: 15px 0;
    display: grid;
    align-items: center;
    column-gap: 15px;
    grid-template-columns: 36px 1fr;
  }


  & .loading-animation {
    width: 36px;
    height: 36px;
    border: 2px solid #e7e7e7;
    border-top: 2px solid #999;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: auto;
    display: block;
  }


  & .loading-text {
    font-size: 26px;
    color: #555;
  }  
}
