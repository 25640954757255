.create__order{
    .gl__sidebar {
            position: fixed;
            top: 0;
            bottom: 0;
            z-index: 998;
            background-color: $white;
            width: 100%;
            max-width: 480px;
            height: 100%;
            overflow-y: scroll;
            transition: transform 0.3s ease;
            will-change: transform;
    
            &--left {
                left: 0;
                transform: translateX(-100%);
    
                &.isActive {
                    transform: translateX(0%);
                }
            }
    
            &--right {
                right: -12px;
                transform: translateX(100%);
    
                &.isActive {
                    transform: translateX(0%);
                }
            }
    
            // Header 
            &-header {
                position: sticky;
                top: 0;
                right: 0;
                left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                grid-gap: 15px;
                justify-content: space-between;
                height: 128px;
                z-index: 100;
                background-color: $white;
                border-bottom: 1px solid #d9d9d9;
                padding-inline: 24px;
    
                @media (min-width: 1024px) {
                    height: 80px;
                }
    
                & h4 {
                    font-size: 20px;
                }
    
                & button {
                    padding: 0;
                }
            }
    
            // Content 
            &-content {
                display: grid;
                padding: 30px 24px;
                min-height: calc(100vh - 128px);
    
                @media (min-width: 1024px) {
                    min-height: calc(100vh - 80px);
                }

                & >form{
                    &>:nth-child(1){
                        & h3{
                            font-size: 24px;
                            text-align: center;
                            color: hsl(0deg, 0%, 25%);
                            margin-bottom: 20px;
                            justify-content: center;
                        }
                        & img{
                            display: block;
                            max-width: 100%;
                            width: initial;
                            height: initial;
                            background: none;
                            opacity: 1;
                            border: 0px;
                            margin: 0px;
                            padding: 0px;
                        }
                    }
                }

                & .form-group.actions, & .actions{
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    & button{
                        width: 100%;
                    }
                }

                & .form-group-wrapper{
                    
                    & .form-group{
                        margin-bottom: 30px;
                        position: relative;

                        & input{
                            position: relative;
                            display: block;
                            transition: background-color 0.25s ease;
                            line-height: 27px;
                            padding: 0 15px;
                            height: 54px;
                            width: 100%;
                            border-radius: 6px;
                            border: 2px solid #e6e6e6;

                            & +label{
                                position: absolute;
                                top: 13px;
                                left: 20px;
                                color: #666;
                                pointer-events: none;
                                transition: all 0.25s ease;
                                padding: 0;
                                display: inline-block;
                                padding: 3px;
                                z-index: 2;

                                &::before {
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    width: 100%;
                                    height: 4px;
                                    background-color: $white;
                                    margin: auto;
                                    content: '';
                                    clear: both;
                                    z-index: -1;
                                }
                            }
                            &:focus{
                                background-color: white;
                                border-color: hsl(0deg, 0%, 35%);
                                outline: none;

                                & +label{
                                    top: -13px;
                                    left: 10px;
                                    color: hsl(0deg, 0%, 70%);
                                }
                            }
                            &:not(:placeholder-shown) + label{
                                top: -13px;
                                left: 10px;
                                color: hsl(0deg, 0%, 70%);
                            }
                        }
                    }
                }
            }
    
            // Loading {
            &-loading {
                height: auto;
                min-height: auto;
    
                & .container-fluid {
                    padding: 0;
                    display: block;
    
                    & .wrapper {
                        position: relative;
                        width: max-content;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        grid-gap: 15px;
                        margin-top: 20px;
    
                        & h4,
                        & span {
                            display: inline-flex;
                        }
    
                        & .text {
                            font-size: 26px;
                            color: #555;
                        }
    
                        & .loader {
                            position: relative;
                            width: 32px;
                            height: 32px;
                            display: block;
                            border: 2px solid #e7e7e7;
                            border-bottom-color: #999;
                            border-radius: 50%;
                            display: block;
                            animation: rotation 1s linear infinite;
                            margin: 0;
                        }
                    }
                }
            }
        }
    
        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
    
            100% {
                transform: rotate(360deg);
            }
        }
}