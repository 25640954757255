.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1048;

  &.isActive {
    opacity: 1;
    visibility: visible;
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
}

.modal, .variableProductModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  max-width: 450px;
  height: 248px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(132.49deg, #FFFFFF 0%, #D9D9D9 100%);
  backdrop-filter: blur(15px);
  border-radius: 25px;
  z-index: 1050;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  transform: translateY(-20px);

  &-full {
    width: 100%;
    max-width: calc(100% - 40px);
    height: 100%;
    max-height: calc(100% - 40px);
  }

  &.isActive {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  & .header {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    & .title {
      margin-bottom: 0;
    }

    & .btn {
      &.close-button {
        display: grid;
        align-items: center;
        justify-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        transition: transform 0.3s ease;
              
        &:hover {
          transform: rotate(90deg);
        }

        & .icon {
          display: block;
          width: 20px;
          height: 20px;

          & img {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  & .content {
    padding: 0 30px;
    display: flex;
    width: 100%;
    height: max-content;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    & h4 {
      color: #636363;
      text-align: center;
      margin-bottom: 60px;
      font-weight: 600;
      font-size: 22px;
      line-height: 24px;
    }

    & h2 {
      font-size: 20px;
      line-height: 26px;
    }

    & button {
      max-width: 120px;
    }

    & form {
      width: 100%;
    }
  }
}

.variableProductModal{
  height: 500px;
  max-width: 850px;

  @media (max-width:1200px){
    max-width: 80%;
  }

  & .content{
    height: 100%;
    justify-content: flex-start;
    overflow: auto;
  }
}