.gl__sidebar {
  position: fixed;

  &--layout {
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    max-width: 250px;
    background-color: $blue40;
    padding: 30px 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 992;
  }

  @media (max-width: 1199.98px) {
    &--layout {
      position: fixed;
      max-width: 320px;
      transform: translateX(-100%);
      transition: transform 0.2s ease;
      will-change: transform;

      &.isActive {
        transform: translateX(0%);
      }
    }
  }

  @media (min-width: 1200px) {
    &--left {
      transform: translateX(0%);
    }
  }


  &--layout {
    // LOGO BRAND 
    & .logo {
      margin: 0 20px 60px 20px;
    }

    // ACCOUNT 
    & .account {
      padding: 20px 0;
      margin: 0 20px;
      // border-top: 1px solid rgba(255, 255, 255, 0.3);

      & ul {
        list-style-type: none;
        display: grid;
        row-gap: 20px;
        & li {
          & a, 
          & button {
            height: 36px;
            display: grid;
            grid-template-columns: 20px 1fr;
            column-gap: 12px;
            align-items: center;
            color: $white;
            font-weight: $fontWeight600;
            width: 100%;

            // ICON 
            & .icon {
              width: 20px;
              height: 20px;

              & .img {
                display: block;
                width: auto;
                height: 20px;
              }
            }

            // TEXT 
            & .text {
              display: block;
              text-align: left;
            }
          }

          & a {
            position: relative;

            &::before {
              position: absolute;
              left: -20px;
              content: '';
              clear: both;
              width: 6px;
              height: 100%;
              border-radius: 0 5px 5px 0;
              background-color: transparent;
              transition: background-color 0.3s ease;
            }

            &:hover::before,
            &.active::before {
              background-color: $white;
            }
          }
        }
      }
    }
  }

  & nav {
    position: relative;
    margin: 0 20px;
    
    & ul {
      display: grid;
      row-gap: 20px;
      list-style-type: none;
      margin-bottom: 0;
      // padding: 20px 0;
      // border-top: 1px solid rgba(255, 255, 255, 0.3);

      & li {

        &:first-child {
          margin-top: 20px;
        }

        &:last-child {
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
        
        & a {
          position: relative;
          height: 36px;
          display: grid;
          grid-template-columns: 20px 1fr;
          column-gap: 12px;
          align-items: center;
          color: $white;
          font-weight: $fontWeight600;

          &::before {
            position: absolute;
            left: -20px;
            content: '';
            clear: both;
            width: 6px;
            height: 100%;
            border-radius: 0 5px 5px 0;
            background-color: transparent;
            transition: background-color 0.3s ease;
          }
          
          &:hover::before,
          &.active::before {
            background-color: $white;
          }
        }
      }
    }
  }
}

// OVERLAY of SIDEBAR 
@media (max-width: 1199.98px) {
  .backdrop-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 991;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    will-change: opacity, visibility;

    &.isActive {
      opacity: 1;
      visibility: visible;
    }
  }
}

@media (min-width: 1200px) {
  .backdrop-overlay {
    position: static;
    display: none;
    width: 0;
    height: 0;
  }
}

// SIDEBAR MODAL 
.sidebar__modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 540px;
  height: 100%;
  overflow-y: auto;
  z-index: 1050;
  background-color: $white;
  padding-bottom: 40px;
  transition: all 0.3s ease;

  &--left {
    left: 0;
    transform: translateX(-100%);
    &.isActive {
      transform: translateX(0%);
    }
  }

  &--right {
    right: 0;
    transform: translateX(100%);

    &.isActive {
      transform: translateX(0%);
    }
  }

  &--full {
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);

    &.isActive {
      top: 0;
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  // HEADER
  & .header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    padding: 0 40px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    background-color: $white;
    z-index: 102;

    & .title {
      color: $blue40;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 0;
    }

    & .close-button {
      display: grid;
      align-items: center;
      justify-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transition: transform 0.3s ease;

      &:hover {
        transform: rotate(90deg);
      }

      & .icon {
        display: block;
        width: 20px;
        height: 20px;

        & img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  // CONTENT 
  & .content {
    position: relative;
    padding: 40px;

    // content form 
    & .ant-form {
      &-item {
        margin-bottom: 20px;
      }

      & input {
        height: 50px;
        padding: 0 15px;
        border-radius: 6px;
        accent-color: $blue40;
      }

      & select {
        height: 50px;
        padding: 0 15px;
        border-radius: 6px;
      }
    }
  }

  // DELETE BUTTON FORM 
  .delete-button-form {
    position: fixed;
    top: 27px;
    right: 106px;
    z-index: 1061;
    width: 36px;
    height: 36px;

    & .btn {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      position: relative;
      width: 100%;
      height: 100%;


      & .icon {
        width: 100%;
        height: 100%;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    & .ant-form-item {
      position: relative;
      top: 0;
      left: 0;
      width: 36px;
      height: 36px;
      margin: 0;
      overflow: hidden;
    }
  }
}

// MODAL OVERLAY 
.modal-overlay, .variableProductModal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1049;
  background-color: rgba($color: #000000, $alpha: 0.3);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  // show sidebar overlay
  &.isActive {
    opacity: 1;
    visibility: visible;
  }
}


// TOGGLE SWITCH SITE in SIDEBAR
.switch-site {
  position: relative;
  display: grid;
  row-gap: 20px;
  margin: 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  & .btn {
    background-color: transparent;
    color: $white;
    display: grid;
    width: 100%;
    grid-template-columns: 20px 1fr 20px;
    column-gap: 12px;
    padding: 12px 15px;
    border-radius: 10px;
    background-color: rgba($color: $white, $alpha: 0.2);

    & span {
      display: block;
      text-align: left;

      &.text {
        font-weight: 600;
      }

      &.icon {
        display: grid;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;

        & img {
          display: block;
          width: 18px;
        }

        &-arrow {
          transition: transform 0.3s ease;
        }
      }
    }
  }

  &.isActive {
    & .btn {
      & .icon-arrow {
        transform: rotate(180deg);
      }
    }
  }

  & .dropdown {
    margin: 0;
    transition: height 0.3s ease;
  
    & ul {
      margin: 0;
      display: grid;
      list-style-type: none;
      margin-bottom: 20px;
      row-gap: 15px;

      & li {
        & a {
          position: relative;
          display: grid;
          align-items: center;
          height: 36px;
          font-weight: 700;
          color: $white;
          padding-left: 48px;
          background-color: transparent;
          border-radius: 5px;

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 4px;
            background-color: transparent;
            content: '';
            clear: both;
            border-radius: 10px;
            overflow: hidden;
          }

          &.active {
            // background-color: rgba($color: $white, $alpha: 0.2);
            &::after {
              // background-color: $light40;
            }
          }
        }
      }
    }
  }
}

// NAVBAR
.navbar {
  position: relative;
  padding: 20px 0;
  display: grid;
  row-gap: 20px;

  & ul,
  & .group {
    margin: 0;
    list-style-type: none;
    &:not(:last-child) {
      &>li {
        &:last-child {
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          padding-bottom: 20px;
        }
      }
    }

    & .item {
      line-height: 50px;
      & .navlink {
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 0 20px;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 6px;
          height: 100%;
          background-color: transparent;
          border-radius: 0 5px 5px 0;
          content: '';
          clear: both;
          display: block;
          transition: all 0.3s ease;
        }

        &.active {
         &::before {
          background-color: $white;
         }
        }
  
        & .icon {
          width: 18px;
          height: 18px;
          background-color: transparent;
          & .img {
            display: block;
            width: 100%;
          }
        }
        & .text {
          color: $white;
          font-weight: $fontWeight600;
        }
      }

      & .btn {
        display: grid;
        grid-template-columns: 18px 1fr;
        align-items: center;
        column-gap: 10px;
        width: 100%;
        & .icon {
          width: 18px;
          height: 18px;
          background-color: transparent;
          display: block;

          & .img {
            display: block;
            width: 100%;
          }
        }
        & .text {
          display: block;
          width: 100%;
          text-align: left;
          color: $white;
          font-weight: $fontWeight600;
        }
      }    

      &-account {
        & .btn {
          padding: 0 20px;
        }
      }
    }

    & .dropdown {
      margin-bottom: 0;
      padding: 0 20px;
      & .btn {
        grid-template-columns: 18px 1fr 18px;

        & .icon-arrow {
          display: flex;
          transition: transform 0.3s ease;
        }
      }

      &.isActive {
        & .btn {
          & .icon-arrow {
            transform: rotate(180deg);
          }
        }
      }

      & .submenu {
        position: relative;
        transition: all 0.3s ease;


        & .item {
          & .navlink {
            padding-left: 28px;
            margin-left: 20px;
            border-radius: 10px;

            &.active {
              background-color: rgba($color: $white, $alpha: 0.2);
            }

            &::before {
              border-radius: 0;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}