.section {

  // SECTION WRAPPER
  &__wrapper {
    height: calc(100% - 50px);
    overflow-y: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .2);
  }

  // HEADER of SECTION
  &__header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: #{$sectionHeaderHeight};
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    column-gap: 20px;
    background-color: $white;
    z-index: 100;

    &.isLoading {}

    // header filter option (show result per page)
    & .filter {
      display: flex;
      column-gap: 10px;
      align-items: center;

      &-date {
        position: relative;
        width: 351px;

        & .placeholder-content {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      & h3 {
        color: $dark20;
        margin: 0;
        font-size: 18px;
        font-weight: $fontWeight400;
        line-height: $defaultRatio;
      }

      & select {
        color: $dark70;

        &:hover {
          border-color: $blue40;
        }
      }
    }

    // search
    & .search {
      position: relative;
      width: 100%;
      max-width: 850px;

      &-form {
        display: grid;
        grid-template-columns: 1fr auto;
        height: 50px;

        & .form-group {
          position: relative;
          display: grid;
          grid-template-columns: 50px 1fr 50px;

          &:hover {

            & .input,
            & .icon {
              border-color: $blue40;
            }
          }

          & .input {
            border: 0;
            width: 100%;
            height: 100%;
            padding-left: 10px;
            padding-right: 10px;
            border-top: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
            transition: border-color 0.3s ease;
            color: $dark70;

            &:focus,
            &:active {
              box-shadow: none;
            }
          }

          & .icon {
            width: 50px;
            height: 50px;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            transition: border-color 0.3s ease;

            &-search {
              border-top: 1px solid #d9d9d9;
              border-bottom: 1px solid #d9d9d9;
              border-left: 1px solid #d9d9d9;
              border-radius: 5px 0 0 5px;
            }

            &-close {
              border-top: 1px solid #d9d9d9;
              border-bottom: 1px solid #d9d9d9;
              border-right: 1px solid transparent;
              cursor: pointer;

              &.isActive {
                img {
                  opacity: 1;
                }
              }

              & img {
                opacity: 0;
                padding: 5px;
                width: 24px !important;
                background-color: #d9d9d9;
                border-radius: 50%;
                transition: opacity 0.3s ease;
              }
            }

            & img {
              width: 16px;
            }
          }
        }

        & .form-submit {
          border: 0;
          background-color: $white;
          color: $blue40;
          width: 100px;
          border: 1px solid #d9d9d9;
          border-radius: 0 5px 5px 0;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }

    // header actions of page 
    & .actions {
      display: flex;
      column-gap: 10px;
    }
  }
}