.customers__table {
    padding: 30px 0px 30px 30px;
    width: 100%;
    min-width: 690px;

    & .header {
        display: flex;
        align-items: center;
        height: 50px;
        font-size: 20px;
        margin-bottom: 20px;

    }

    & .accordion {

        & .accordion__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            padding: 15px 25px;
            border: 1px solid lightgray;
            transition: border-radius .3s .15s ease;
            cursor: pointer;

            &.bottomRadius {
                border-bottom-left-radius: 13px;
                border-bottom-right-radius: 13px;
            }

            &.topRadius {
                border-top-left-radius: 13px;
                border-top-right-radius: 13px;
                transition-delay: 0;
            }

            & .title>h3 {
                margin: 0;
                font-size: 16px;
            }
        }

        & .accordion__body {
            max-height: 0px;
            transition: max-height .3s ease-out;
            border: 1px solid lightgray;
            border-top: 0;
            border-bottom: 0;
            overflow: hidden;

            & .customers__container {
                display: flex;
                flex-direction: column;
                position: relative;
                background-color: lighten($color: gray, $amount: 48);
                border: 1px solid lightgray;
                border-bottom: none;

                & .customer__table {
                    &>thead {
                        background-color: lighten($color: gray, $amount: 48);
                        border-bottom: 1px solid lightgray;
                        z-index: 999;

                        &>tr {
                            &>th {
                                text-align: left;
                                padding: 20px 12px;
                            }
                        }
                    }

                    &>tbody {

                        &>tr {
                            border-bottom: 1px solid lightgray;

                            &>:nth-child(1) {
                                text-align: center;

                                &>input {
                                    width: 15px;
                                    height: 15px;
                                }
                            }

                            &>td {
                                padding: 12px;
                            }
                        }
                    }
                }
            }

            &.isActive {
                max-height: 1000px;
                border: 1px solid lightgray;
                border-top: none;
                transition: all .3s .2s ease-in-out;
                overflow: hidden;
            }

            &.borderBottom {
                border-bottom-left-radius: 13px;
                border-bottom-right-radius: 13px;
            }

            & .newAcc,
            & .newAddress {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                gap: 25px;
                padding: 20px 0px;
                background-color: lighten($color: gray, $amount: 48);

                & .row {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    width: 60%;
                    position: relative;

                    &>label {
                        font-size: 14px;
                    }

                    &>div {
                        position: relative;

                        &>img {
                            position: absolute;
                            top: 50%;
                            right: 5px;
                            transform: translate(-5px, -50%);
                            cursor: pointer;
                        }
                    }

                    & input,
                    & select {
                        padding: 10px;
                        border-radius: 6px;
                        width: 100%;
                    }

                    & select {
                        appearance: none;
                        -moz-appearance: none;
                        outline: none;
                        border-color: lightgray;

                        &:focus,
                        &:hover {
                            border-color: var(--official-color);
                        }
                    }

                    & .generateBtn {
                        color: $blue40;
                        border-color: $blue40;
                        background-color: white;
                    }

                    & .errorMsg {
                        color: red;
                        font-size: 14px;
                        margin: 0;
                        position: absolute;
                        bottom: -20px;
                        left: 0px;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(-10px);
                        transition: all .3s ease;

                        &.active {
                            opacity: 1;
                            visibility: visible;
                            transform: translateY(0px);
                        }
                    }
                }
            }

            & .existingAddress {
                background-color: lighten($color: gray, $amount: 48);
                padding: 20px 30px;
                max-height: 450px;
                overflow-y: scroll;

                & .row {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    margin: 20px 0px;

                    & .address__name {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        &>input {
                            width: 15px;
                            height: 15px;
                        }

                        &>label {
                            color: $blue40;
                        }
                    }

                    & .address__info {
                        display: flex;
                        gap: 20px;
                        justify-content: space-between;

                        &>div {
                            width: 100%;

                            &>input {
                                width: 100%;
                                padding: 10px;
                                border-radius: 6px;
                            }
                        }
                    }
                }
            }

            & .noExistingAddress {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 20px;
                padding: 30px 0px 40px;
                background-color: lighten($color: gray, $amount: 48);

                & .message {
                    display: flex;
                    align-items: flex-end;
                    gap: 10px;

                    &>span {
                        color: #4762E6;
                        font-weight: 600;
                    }
                }

                & .button__container {
                    & .createBtn {
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                }
            }
        }
    }

    & .shipping__options {
        display: flex;
    }

    & .splitAddress__container {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 20px 0px;

        &>label {
            color: $blue50;
        }
    }
}