.couponDatePicker {
    width: 100%;
    padding: 0px 15px;
    border-radius: 6px;
    accent-color: var(--official-color);

    &:hover{
        border-color: var(--official-color);
    }
    &.ant-picker-focused{
        box-shadow: none;
        border-color: var(--official-color);
    }

    & input {
        padding: 0 !important;

    }
}
.couponSelect{
    font-size: 14px !important;
}