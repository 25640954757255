.create__order {

    & .payment__preview,
    & .payment__summary {
        & p {
            margin: 0;
        }
    }

    & .payment__summary {
        min-width: 660px;

        & .checkout__container {
            display: flex;
            justify-content: flex-end;
            margin: 10px 0px;
            gap: 15px;

            & button {
                padding: 12px 24px;
            }

            &>:nth-child(1) {
                width: 160px;
            }

            & .btn-success {
                width: 240px;
                font-size: 18px;
                background-color: hsl(130, 100%, 31%);
                color: white;
                border-radius: 4px;
                border: none;
                transition: background-color .3s ease;
                cursor: pointer;

                &:hover {
                    background-color: darken($color: hsl(130, 100%, 31%), $amount: 4);
                }
            }
        }

        & .coupon-row {
            border: 1px solid lightgray;
            padding: 20px;

            & h3 {
                font-size: 16px;
            }

            & .coupon-search {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: space-between;

                & input {
                    width: 100%;
                    height: 40px;
                    border: 1px solid lightgray;
                    border-radius: 4px;
                    padding-inline: 10px;

                    &.notValid {
                        border: 1px solid red;
                    }
                }

                & .button-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    & button {
                        height: 40px;
                        padding: 0px 25px;
                        border-color: $blue40;
                    }
                }
            }
        }

        & .calculate-total {
            border: 1px solid #e6e6e6;
            border-top: none;
            padding: 20px;

            & h5,
            h4 {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            & h5 {
                font-size: 16px;
            }

            & h4 {
                font-size: 22px;
            }

            & .total-shipping,
            & .total-split-shipping {
                display: flex;
                flex-direction: column;
                gap: 15px;

                & .separator {
                    height: 1px;
                    background-color: #e6e6e6;
                    width: 100%;
                }
            }
        }

        & .rest-payment-content {

            & .toggle-payment-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                border: 1px solid lightgray;
                border-top: none;
                padding: 20px;

                & button {
                    height: 40px;
                }

                & p {
                    margin: 0;
                }
            }
        }

        & .payment-method-content {
            border: 1px solid lightgray;
            border-top: none;

            & .form-group-split-payment {
                padding: 20px;
                margin: 0;
                border-bottom: 1px solid lightgray;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 15px;

                & .left {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                }

                & .right {}
            }

            & .payment-list {

                & .form-group-radio,
                & .form-group-checkbox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                    margin: 0;
                    padding: 20px;
                    border-bottom: 1px solid lightgray;

                    & .left {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        & .card-details {
                            display: flex;
                            align-items: center;
                        }

                        & label {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            & .image {
                                & img {
                                    object-fit: contain;
                                }
                            }
                        }
                    }

                    & .right {
                        & .action-wrapper {
                            position: relative;

                            & .btn-action {
                                background-color: white;
                                border-radius: 6px;
                            }
                        }

                        & .dropdown {
                            position: absolute;
                            top: 57px;
                            right: 0;
                            background-color: $white;
                            border-radius: 4px;
                            padding: 10px;
                            z-index: 100;
                            min-width: 170px;
                            box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
                            opacity: 0;
                            visibility: hidden;
                            transition: opacity 0.3s ease, visibility 0.3s ease, top 0.3s ease;
                            will-change: opacity, visibility, top;
                            display: grid;
                            grid-gap: 10px;

                            &.isActive {
                                opacity: 1;
                                visibility: visible;
                                top: 77px;
                            }


                            & .btn-action {

                                &-update,
                                &-delete {
                                    padding: 7px;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    grid-gap: 7px;
                                    transition: background-color 0.3s ease, border-color 0.3s ease;
                                    background-color: rgba($color: $light5, $alpha: 0.5);
                                    border-radius: 4px;

                                    & .icon {
                                        width: 24px;
                                        height: 24px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }

                                    & .text {
                                        font-size: 16px;
                                        font-weight: 600;
                                    }
                                }

                                &-update {
                                    border-color: rgba($color: $light5, $alpha: 0.5);

                                    &:hover,
                                    &:focus {
                                        background-color: rgba($color: $blue70, $alpha: 0.15);
                                        border-color: rgba($color: $blue70, $alpha: 0.15);
                                    }

                                    & .text {
                                        color: $blue80;
                                    }
                                }

                                &-delete {
                                    border-color: rgba($color: $light5, $alpha: 0.5);

                                    &:hover,
                                    &:focus {
                                        background-color: rgba($color: $red70, $alpha: 0.15);
                                        border-color: rgba($color: $red70, $alpha: 0.15);
                                    }

                                    & .text {
                                        color: $red70;
                                    }
                                }
                            }
                        }

                        & .actions {
                            display: flex;
                            gap: 15px;

                            & .amount-wrapper {

                                & .prefix {
                                    position: relative;
                                    display: grid;
                                    grid-template-columns: 40px 1fr;
                                    width: 140px;

                                    &.prefix-disabled {
                                        opacity: .5;
                                    }

                                    & .cash {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 1px solid #D9D9D9;
                                        background-color: hsl(0deg, 0%, 95%);
                                        border-radius: 4px 0 0 4px;
                                    }

                                    & input {
                                        border: 1px solid #D9D9D9;
                                        border-left: 0;
                                        width: 100%;
                                        height: 40px;
                                        border-radius: 0 4px 4px 0;
                                        -moz-appearance: none !important;
                                        appearance: none !important;
                                        -webkit-appearance: none !important;
                                    }
                                }
                            }

                            & .action-wrapper {
                                position: relative;
                                display: grid;
                                justify-content: flex-end;

                                & .btn-action {
                                    margin-top: 20px;

                                    & img {
                                        background-color: white;
                                        border-radius: 6px;
                                    }
                                }
                            }
                        }
                    }
                }

                &.success {
                    background-color: rgba($color: green, $alpha: .1);
                    border: 1px solid green;
                }

                &.error {
                    background-color: rgba($color: red, $alpha: .1);
                    border: 1px solid red;
                }

                & .no-data-found {
                    padding: 20px;
                    display: grid;
                    place-content: center;

                    &.error {
                        background-color: rgba(237, 74, 90, 0.1);
                        border: 1px solid hsl(354deg, 82%, 61%);
                    }
                }
            }

        }
    }

    & .payment__preview {

        & .checkout-review-wrapper {
            max-height: 400px;
            overflow-y: auto;
            border: 1px solid #e6e6e6;
            padding: 20px;

            & .checkout-content {
                & .left {
                    display: grid;
                    grid-template-columns: 1fr;
                    column-gap: 30px;
                    height: max-content;

                    & .group {
                        margin-bottom: 20px;

                        & .table-overflow {
                            display: grid;
                            overflow-x: hidden;

                            & .table {
                                width: 100%;
                                max-width: 100%;
                                border-collapse: collapse;
                                border: 1px solid #e6e6e6;
                                border-radius: 4px;
                                overflow-x: auto;
                                white-space: nowrap;

                                &>thead {
                                    border-bottom: 1px solid #e6e6e6;


                                    &>tr {
                                        background-color: #fafafa;

                                        &>th {
                                            text-align: left;
                                            padding: 15px;

                                        }
                                    }
                                }

                                &>tbody {
                                    &>:nth-child(even) {
                                        background-color: #fafafa;
                                    }

                                    &>tr {
                                        &>td {
                                            padding: 15px;
                                            text-align: left;
                                            border-bottom: 1px solid #e6e6e6;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & .delete-payment-modal {
        & .form-delete {
            & .actions {
                grid-template-columns: repeat(2, 1fr);
                place-items: center;
                column-gap: 10px;

                & button {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}